.bombayhardware-card{
    height: 340px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
}

.bombayhardware-image{
    height: 150px !important;
    width: 120px !important;
}

.bombayhardware-first{
    padding: 10px 15px;
    height: 340px;
}

.bombayhardware-first-text{
    padding: 20px 10px;
}

.am-title{
    font-size: 1.5rem;
    font-weight: 300;
    color: #3d5266;
}

.bombayhardware-second{
    padding: 10px 15px;
}

// PRODUCT LISTING
.bombayhardware-card1{
    height: 340px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
    width: 410px;
    margin-left: 10px;
    margin-top: 15px;
}

.bombayhardware-first1{
    padding: 10px 10px;
    height: 340px;
}

.bombayhardware-image1{
    height: 120px !important;
    width: 100px !important;
}

.bombayhardware-first-text1{
    padding: 10px 5px;
    padding-left: 15px;
}

.am-title1{
    font-size: 1.1rem;
    font-weight: 300;
    color: #3d5266;
}

// COMPANY PROFILE

#am-product{
    font-size: 14px;
    font-family: 'proxima-regular','sans-serif' !important;
    .am-page-title {
        color: #1f1f1f;
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 5px;
        font-family: 'proxima-regular','sans-serif' !important;
    }

    .container-fluid{
        padding:30px;
    }

    .ampage-header {
        margin-bottom: 1.875rem;
    }

    .card{
        border: 1px solid #ededed;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        height: fit-content;
    }

    .profile-view {
        position: relative;
    }

    .profile-img-wrap {
        height: 120px;
        position: absolute;
        width: 120px;
        background: #fff;
        overflow: hidden;
    }

    .profile-img {
        width: 120px;
        height: 120px;
        margin: 0 auto;
        position: relative;
    }

    .profile-basic {
        margin-left: 140px;
        padding-right: 50px;
    }

    .profile-info-left {
        border-right: 2px dashed #ccc;
    }

    .user-name {
        color: #333;
    }

    .personal-info {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        padding-top: 15px;
        .li{
            padding-top: 8px;
        }
    }

    .ami-title {
        color: #4f4f4f;
        float: left;
        font-weight: 500;
        margin-right: 30px;
        width: 25%;
    }

    .ami-text {
        color: #8e8e8e;
        display: block;
        overflow: hidden;
    }

    a {
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
    }

    .ai-mt{
        margin-top: 5px;
    }

    .tab-box {
        border-bottom: 0;
        margin-bottom: 10px;
        padding: 0;
    }

    .user-tabs{
        padding: 10px 15px;
    }

    .amcard-title {
        color: #1f1f1f;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}

#bombayhardwarecards{
    font-family: 'proxima-regular','sans-serif' !important;
    .bombayhardware{
        min-height: 450px;
        margin: 0px 10px;
        padding: 10px 15px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border: 1px solid #999;
        border-radius: 12px;
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        &-img-holder{
            display:block;
            height: 140px;
            width: 100%;
            display: flex;
            justify-content: center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            margin: 0.2px;
        }
        &-img{
            height: 130px;
            width: 130px;
            // border-radius: 50%;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
            padding: 10px;
            display: flex;
            justify-content: center;
            // overflow: hidden;
        }
        &-description-holder{
            display: block;
        }
        &-title{
            font-size: 20px;
            font-weight: 550;
            color: rgb(59, 59, 59);
        }
        &-subtitle{
            padding-top: 1px;
            padding-bottom: 6px;
            font-size: 15px;
            font-weight: 500;
            color: rgb(138, 138, 138);
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-tabs{
            margin-top: 8px;
            ul{
                display: flex;
                justify-content: center;
            }
            li{
                padding: 6px 9px;
                padding-right: 10px;
                background-image: linear-gradient(to right top, #E31E25, #E31E25, #E31E25, #E31E25, #E31E25);
                border-radius: 50%;
                margin-left: 10px;
                a{
                    i{
                    color: #fff !important;
                    font-size: 20px !important;
                    line-height: 10px !important;
                    width: 20px !important;
                }
            }
            }
            li:hover{
                box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
                cursor: pointer;
            }
            .nav-tabs{
                border-bottom: none !important;
            }
        }
        &-profile{
            margin-bottom: 40px;
            margin-top:15px;
            .profile-title{
                color: rgb(138, 138, 138);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            .profile-description{
                color: rgb(65, 64, 64);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            a{
                font-size: 14px;
            }
        }
        &-contact{
            a{
                font-size: 14px;
            }
            margin-bottom: 40px;
            margin-top:15px;
            .contact-title{
                color: rgb(153, 153, 153);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
            }
            .contact-description{
                color: rgb(65, 64, 64);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
                overflow-wrap: break-word;
            }
        }
        &-submit-btn{
            height: fit-content;
            position: absolute;
            bottom: 15px;
            width: 83%;
            .bombayhardware-btn{
                background: linear-gradient(to right top, #E31E25, #E31E25, #E31E25, #E31E25, #E31E25);
                border: none !important;
                color: #fff;
            }
        }
        &-active{
            .bombayhardware-tabs{
                li{
                    background-image: linear-gradient(to right top, #E31E25, #E31E25, #E31E25, #E31E25, #E31E25);;
                }
            }
        }
    }
   
}

#bombayhardwarecards1{
    font-family: 'proxima-regular','sans-serif' !important;
    .bombayhardware1{
        min-height: 420px;
        min-width: 150px;
        margin: 0px 10px;
        padding: 10px 15px;
        // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        // border: 1px solid #999;
        // border-radius: 12px;
        max-height: 150px;
        overflow-y: auto;
        overflow-x: hidden;
        &-img-holder{
            display:block;
            height: 285px;
            width: 100%;
            display: flex;
            justify-content: center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            margin: 0.2px;
        }
        &-img{
            height: 120px;
            width: 270px;
            // border-radius: 50%;
            // box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
            // padding: 10px;
            // display: flex;
            // justify-content: center;
            // overflow: hidden;
        }
        &-description-holder{
            display: block;
        }
        &-title{
            font-size: 13px;
            font-weight: 550;
            color: rgb(59, 59, 59);
        }
        &-subtitle{
            padding-top: 1px;
            padding-bottom: 6px;
            font-size: 12px;
            font-weight: 500;
            color: rgb(138, 138, 138);
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-tabs{
            margin-top: 8px;
            ul{
                display: flex;
                justify-content: center;
            }
            li{
                padding: 6px 9px;
                padding-right: 10px;
                background-image: linear-gradient(to right top, #E31E25, #E31E25, #E31E25, #E31E25, #E31E25);
                border-radius: 50%;
                margin-left: 10px;
                a{
                    i{
                    color: #fff !important;
                    font-size: 20px !important;
                    line-height: 10px !important;
                    width: 20px !important;
                }
            }
            }
            li:hover{
                box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
                cursor: pointer;
            }
            .nav-tabs{
                border-bottom: none !important;
            }
        }
        &-profile{
            margin-bottom: 40px;
            margin-top:15px;
            .profile-title{
                color: rgb(138, 138, 138);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            .profile-description{
                color: rgb(65, 64, 64);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            a{
                font-size: 14px;
            }
        }
        &-contact{
            a{
                font-size: 14px;
            }
            margin-bottom: 40px;
            margin-top:15px;
            .contact-title{
                color: rgb(153, 153, 153);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
            }
            .contact-description{
                color: rgb(65, 64, 64);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
                overflow-wrap: break-word;
            }
        }
        &-submit-btn{
            height: fit-content;
            position: absolute;
            margin-left: 91px;
            // bottom: 15px;
            // width: 83%;
            .bombayhardware-btn{
                background: linear-gradient(to right top, #E31E25, #E31E25, #E31E25, #E31E25, #E31E25);
                border: none !important;
                color: #fff;
            }
        }
        &-active{
            .bombayhardware-tabs{
                li{
                    background-image: linear-gradient(to right top, #E31E25, #E31E25, #E31E25, #E31E25, #E31E25);;
                }
            }
        }
    }
   
}

#bombayhardware-listing{
    position: relative;
    .bombayhardware{
        min-height: 420px;
        max-height: 420px;
        padding: 10px 10px;
        min-width: 270px;
        max-width: 270px;
        margin-bottom: 12px;
    }
    
}




::-ms-input-placeholder {
	color: #808080 !important;
	opacity: 0.5 !important;
}

body {
	// font-family: "Inter-Medium";
	background-color: $theme-bg;
}
a,
a:hover {
	color: $font-color1;
	text-decoration: none;
}
ul {
	padding-left: 0px;
	margin: 0;
}
li {
	list-style: none;
}

h3,
p {
	// font-family: "Inter-Regular";
}

.product-name {
//   font-family: 'proxima-nova';
}
h6 {
	// font-family: 'proxima-nova';
}
h4 {
	// font-family: "Inter-Medium";
	&.bold {
		// font-family: "Inter-ExtraBold";
	}
	&.sm-bold {
		// font-family: "proxima-nova";
		font-size: 14px !important;
	}
}
.pb-50 {
	padding-bottom: 50px;
}

ul {
	&.two-column{
		columns: 2;
		-webkit-columns: 2;
		-moz-columns: 2;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				margin-right: 15px;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
	&.three-column{
		columns: 3;
		-webkit-columns: 3;
		-moz-columns: 3;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
  }
/** Title **/
.content-title {
	text-align: center;
	padding: 30px 0px 30px 0px;
	.title-box {
		.title {
			display:inline-block;
			position: relative;
			// font-family: 'proxima-nova';
			margin-bottom: 5px;
			font-size: 1.8rem !important;
			color: #d57c30;
			// span {
			// 	background-color: $theme-bg;
			// }
			// &:after {
			// 	content:"";
			// 	width:100%;
			// 	position: absolute;
			// 	bottom:2px;
			// 	height:2px;
			// 	background:#000;
			// 	left:0;
			// 	z-index: -1;
			// }

		}
		.sub-title {
			// font-family: 'Inter-Regular';
			color:#a7a7a7;
			font-size: 1.3rem !important;
		}
	}
}


.row-fluid {
	display: flex;
	flex-flow: wrap;
}
.sub-heading{
	text-transform: uppercase;
    font-size: 17px;
    // font-family: 'Inter-Medium';
    margin-bottom: 20px;
	cursor: pointer;
}


/**Product box one **/
.product-box-one {
	.product-detail {
		background-color: #fff;
		padding:10px;
		position: relative;
		border: 2px solid #eceaea;
		.product-name {
			padding-right:30px;
		}
		.product-option {
			.wishlist {
				.add-to-wishlist {
					position: absolute;
					top: 0px;
					right: 0px;
					font-size: 22px;
				}
			}
		}
	}
}
/**product**/

.product-name {
	font-size: 18px;
}
h6 {
	&.product-name {
		font-size: 19px;
	}
}

/***Listing**/
.collection-wrapper {
    .collection-content {
      background-color: #fff;
    }
    padding-top: 30px;
    padding-bottom: 30px;
    .collection-top {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .collection-filter {
      background-color: #fff;
      .filter-top {
        border-bottom: 1px solid #dddddd;
        .filter-clear {
          padding: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
      .layerd-navigation {
        padding: 10px;
        // font-family: "Inter-Regular";
		border-bottom: 1px solid #dddddd;
		.collection-collapse-block {
			margin-bottom: 10px;
			
		.collection-collapse-block-content {
			padding-left: 30px;
			
			.options {
                margin: 8px 0px 8px 0px;
                input {
                    margin-right: 10px;
                }
                label{
                    margin-bottom: 0px;
                    font-size: 15px;
                    .count {
                        color: #afabab;
                        font-size: 14px;
                    }
                }
            }
		}
	  }
	}
    }
  
    .col-grid-box {
      width: 100%;
      background: #fff;
      padding: 0px 10px 0px 10px;
    }
  
    .sortby {
      .input-group-text {
        background-color: #fff;
        border-radius: 0;
        border-right: 0;
      }
      .sortby-options {
        border-left: 0px;
        border-radius: 0;
      }
    }
  }
  /** Product Details**/
  
.product-details-wrapper {
    .details-top {
        padding: 15px 0px 15px 0px;
    }
    .slider-nav {
        max-width: 400px;
        min-width: 300px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        
    .owl-thumb {
        img{
            opacity: 0.5;
        }
        background-color: #000;    
        cursor: pointer;
        &:hover {
            background-color: transparent;
            border:2px solid #f7f1f1;
            img {
                opacity: 1;
            }
        }
        &.active {
            
            border:2px solid #f7f1f1;
            background-color: transparent;
            img {
                opacity: 1;
            }
        }
    }
    }
    .product-all-details {
        .product-name {
            font-size: 30px;
        }
    }

    .details-wrapper {
        .product-all-details {
            background-color: #fff;
            padding: 0px 15px 15px 15px;
            .price {
				font-size: 24px;
			}
            .row {
                padding:15px;
                .col-left {
                    flex: 1;
                    padding-right: 15px;
                }
                .col-right{
                    min-width: 250px;
                     .add-to-cart {
                        margin-bottom: 30px;
                    }
                    .delivery-option {
                        margin-top: 30px;
                        h6 {
                            .fa {
                                    font-size: 22px;
                                    -webkit-transform: scaleX(-1);
                                    transform: scaleX(-1);
                            }
						}
						.input-group {
							input {
								border-right:0px;
							}
							.input-group-text {
								background-color: #fff;
								border-left:0;
								color:$primary-color;
							}
						}
						
                    }
                   
                }
            }
            
            .description{
                padding-bottom: 15px;
            }
            .product-addtional-details {
                position: relative;
                padding-top: 15px;
                &::before {
                    content: "";
                    position: absolute;
                    width: calc(100% - 198px);
                    height: 2px;
                    background: #707070;
                    opacity: 0.2;
                    box-shadow: 0;
                    top: -10px;
                }
            }
        }
        
    }
    
}

.ng5-slider {
	.ng5-slider-pointer {
		cursor: pointer;
		width: 20px!important;
		height: 20px!important;
		top: -8px!important;
		background-color: #fff !important;
		z-index: 3;
		border-radius: 16px!important;
		box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-webkit-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-moz-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
	}

	 .ng5-slider-span:focus{
		outline: 0;
	}
	.ng5-slider-pointer:after {
		display: none !important;
	}
	.ng5-slider-selection {
		z-index: 2;
		background: $primary-color !important;
		border-radius: 2px;
	}
}

/** Pages **/

.homepage-banner-slider {
  .owl-theme {
    .owl-dots { 
      position: absolute;
			bottom: 15px;
			left: 50%;
	}
	.owl-next,
	.owl-prev {
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		background-color: $theme-bg !important;
		border: 0px !important;
		.fa {
			font-size: 30px;
		}
	}
	.owl-next{
		right:0;
		background:$theme-bg;
		width:20px;
	}
	.owl-prev{
		left:0;
		background:$theme-bg;
	}
  }
}
.rounder-corner-nav {
	&.top-nav-right{
		.owl-theme {
			.owl-nav {
				position: absolute;
				top: -70px;
				right: 0;
			}
	}
}
&.top-nav-bg-white{
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
					background: #fff;
					border: 0px;
					.fa {
						color:#000;
						
					}
			}
		}
}
}
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
				border-radius: 20px;
				width: 40px;
				height: 40px;
				background: transparent;
				border: 2px solid #b7b6b5;
				.fa {
					color:#b7b6b5;
					font-size: 26px;
				}
			}
		}
	}
}
.rotate-dot{
	.owl-theme {
		.owl-dots {
		
			transform: translateX(-50%);
			[class*="owl-"] {
				width: 25px;
				height: 25px;
				transform: rotate(130deg);
				margin: 0px 6px;
				border: 1px solid transparent;
				&.active {
					border: 1px solid $primary-color;
					span {
						background-color: $primary-color;
					}
				}

				span {
					width: 12px;
					height: 12px;
					border-radius: 0;
					margin: 5px 5px;
				}
			}
		}
	}
}


.featured-collections {
  .tab-image {
          min-height: 300px;
          text-align: center;
          position: relative;
          margin-bottom: 30px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
          .background-image {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s;
                min-height: 300px;
                &:hover {
                  transform: scale(1.2);
                }
          }
          .bg-content{
            position: absolute;
            top:50%;
            left:50%;
			transform: translate(-50%, -50%);
			 color:#fff;
            font-size: 25px;
           
			  p{
				  font-size: 15px;
			  }
           
          }
          
  }

  .tab-image1 {
	min-height: 208px;
	text-align: center;
	position: relative;
	margin-bottom: 30px;
	cursor: pointer;
	overflow: hidden;
	position: relative;
	@media (max-width: 1920px) {
		.background-image1 {
			height: 100%;
		  //   width: 90%;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			-webkit-transition: all .5s;
			-moz-transition: all .5s;
			-o-transition: all .5s;
			transition: all .5s;
				min-height: 208px;
				&:hover {
				  transform: scale(1.2);
				}
		  }
	}

	@media (min-width: 481px) and (max-width: 1400px) {
		.background-image1 {
			height: 100%;
		  //   width: 90%;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			-webkit-transition: all .5s;
			-moz-transition: all .5s;
			-o-transition: all .5s;
			transition: all .5s;
				min-height: 180px;
				&:hover {
				  transform: scale(1.2);
				}
		  }
	}

	.bg-content{
	  position: absolute;
	  top:50%;
	  left:50%;
	  transform: translate(-50%, -50%);
	   color:#fff;
	  font-size: 25px;
	 
		p{
			font-size: 15px;
		}
	 
	}
	
}
}


.best-products {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 30px;
  .title {
	  text-align: center;
	  padding-bottom: 20px;
	   h2 {
		// font-family: 'proxima-nova';
	}
  }
  .column {
      
      -webkit-flex: 1; /* Safari */
      -ms-flex: 1; /* IE 10 */
      flex: 1; /* Standard syntax */
      
      &.grid-image {
          -webkit-flex: 2; /* Safari */
          -ms-flex: 2; /* IE 10 */
          flex: 2; /* Standard syntax */
      }
      &.single-image {
          border: 1px solid #c5c5c5;
          margin-bottom:20px;
          text-align: center;
          .trers-single-image {
              img {
                  min-width: 300px;
              }
          }
          h4 {
              font-size: 20px;
              text-transform: uppercase;
          }
      }
      .tyers-grid {
          display: flex;
          flex-flow: wrap;
          margin: 0;
          li {
              width:calc(50% - 20px);
              border: 1px solid #c5c5c5;
              margin: 0px 20px 20px 0px;
              padding:10px;
              display: flex;
			  align-items: center;
			  .trers-grid-image {
				  img {
					  padding: 10px;
				  }
			  }
              a {
				cursor: pointer;
				display:block;
              }
              &:hover {
                border-color: var(--theme-deafult);
              }
              h4 {
                  font-size: 20px;
                  text-transform: uppercase;
              }

          }
      }
    
      
  }
 
}

.mobile-cart{
	display: none !important;
}

.mobile-only{
	display: none !important;
}

.bombayhardware-card-temp{
    border:2px solid #1e1e73;
    width:275px;
    height:350px;
    margin-bottom:20px;
}
#bh-temp-SecondLevel{
    display: none;
}
#bh-temp-SecondLevel1{
    display: none;
}
.bombayhardware-container-temp{
    display: grid;
    grid-template-columns: repeat(3, 1fr);margin-top:10px
}
.brand-bh{
    height: 34px!important;
    position: relative!important;
    width: 100%!important;
    justify-content: center;
    display: flex;
    padding: 8px!important;
    font-size: 10px;
    font-weight: 600;
}
.bh-temp-cart{
    background-color:#1e1e73 !important;
    border:2px solid #150982 !important; 
    padding: 5px 17px !important;
    width:50%;
    margin-top:2%;
}
.size-btn-bh{
    float:left;
    margin-left: 3%;
    box-shadow: 0px 0px 0px 1px #1e1e73;
}
.sch-btn-bh{
    float:right;
    margin-right: 7%;
    box-shadow: 0px 0px 0px 1px #1e1e73;
}
.bh-temp-2level{ 
    padding: 5px; 
    width:73%
}
.dropdown-content-bh{
    display:none
}
.dropdown-bh:hover .dropdown-content-bh {
    display: block;
}
.filters-bh{
    width: 71%;
    border-right: 1px solid #aaa;
}
.summary-bh{
    display: none;
}
.small-cart-bh:hover{
    color:red;
    position: relative;
    top: 58%;
    left: 37%;
    .summary-bh{
        display: block;
        border: 1px solid black;
        background-color: white;
        z-index: 100;
        position: relative;
        padding: 5px;
        width: 220px;
        left:2.5%;
        margin: 4px 79px 0px -99px;
    }   
    .summary-bh:empty{
        display:none;
    } 
}
.small-cart-bh-2:hover{
    color:red;
    position: relative;
    top: 32%;
    left: 30%;
    .summary-bh{
        display: block;
        border: 1px solid black;
        background-color: white;
        z-index: 100;
        position: relative;
        padding: 5px;
        width: 220px;
        left: -40%;
        margin: 4px 79px 0px -99px;

    }   
}
.small-cart-bh-2:hover{
    color:red;
    position: relative;
    top: 58%;
    left: 36.8%;
    .summary-bh{
        display: block;
        border: 1px solid black;
        background-color: white;
        z-index: 100;
        position: relative;
        padding: 5px;
        width: 220px;
        left:2.5%;
    }   
    .summary-bh:empty{
        display:none;
    } 
}
.dropdownstyle1{
    width: 80%;
    margin-left: 10%;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.dropdownstyle2{
    left: 18%; 
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.dropdownstyle2-details{
    left: 52%; 
    position: relative;
    top: -37px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.dropdownstyle-default{
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.small-cart-bh{
    position: relative;
    top: 125%;
    left: -8%;
    
}
.small-cart-bh-2{
    position: relative;
    top: 125%;
    left: -2.5%;
    
}
.bh-title{
    font-weight: 600;
    color:black !important;
    padding:5px;
}
.ttl{
    width: 109%;
}
.ttl .tooltip-bh {
    visibility: hidden;
    width: fit-content  ;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    border: 1px solid black;
    padding: 5px;
    font-size: 10px;
    margin-right: 20%;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 10;
    // width: 120px;
    // bottom: 100%;
    // left: 50%;
    // margin-left: -60px;
}
.ttl:hover .tooltip-bh {
    visibility: visible;
  }
.select-size1{
    color: red!important;
    position: absolute;
    margin-top: -8%;
    left: 10%;
    font-size: 13px;
}
.select-size2{
    color: red!important;
    position: relative;
    left: 7%;
    top: -64px;
    font-size: 13px;
}
.select-size2-1{
    color: red!important;
    position: relative;
    left: 7%;
    top: -64px;
    font-size: 13px;
}
.select-size-listing1{
    color: red!important;
    position: relative;
    font-size: small;
    left: 2%;
    top: -30%;
    margin-top: -20%;
    display: flex;
    width: 1%;
}
.select-size-listing2{
    color: red!important;
    position: relative;
    font-size: small;
    left: 5%;
    margin-top: -20%;
    width: 1%;
    display: flex;
}
.select-size-listing2-1{
    color: red!important;
    position: relative;
    font-size: small;
    left: 63%;
    margin-top: -20%;
    width: 1%;
    display: flex;
}
.select-size-listing2-1-long{
    color: red!important;
    position: relative;
    font-size: small;
    left: 51%;
    margin-top: -20%;
    width: 1%;
    display: flex;
}
.bh-cart-btn{
    margin-top:5%;
}
.bh-temp-desc{
    width:275px !important;
}
.atc-details{
    margin: 0; 
    width: 80%; 
    left: 10%;
    top: -37px;
    position: relative; 
    margin-top: -28%;
}
.atc-details1{
    margin: 0; 
    width: 80%; 
    left: 10%;
    position: relative; 
    margin-top: -28%;
}
.bh-submit{
    position: relative; 
    left: 100%; 
    margin-top: -3%;
}
.cat-posi{
    display: none !important;
}

@media screen and (min-width:601px) {
    .bombayhardware-card-temp:hover{
        -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.75);
    }
}

@media screen and (max-width:600px) {
    .partner_grid{
        display: grid;
        grid-template-columns: repeat(2,1fr)!important;
        padding: 20px;
      }
    
    .home-container2, .home-containeravon {
        grid-template-columns: repeat(2,1fr)!important;
    }
    .centered {
		position: absolute;
		top: 50%!important;
		left: 50%;
		transform: translate(-50%,-50%);
		color: #fff;    
		font-size: 18px!important;
		font-weight: 500;
		white-space: nowrap;
	}
    .tatasteel_img{
        padding:28px 30px!important;
    }
    .jgogas_img{
        padding: 28px 34px!important;
    }
    
    .supreme_img{
        width: 35%!important;
        height: 60%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 3%;
        
    }
    .cat-posi{
        display: flex !important;
    }
    .bombayhardware-container-temp{
        grid-template-columns: repeat(1, 1fr);
    }
    .bh-cart-btn{
        margin-top:20%;
    }
    .bh-submit{
        left: 50%;
        width: 50%;
        top: 45px;
    }
    .bombayhardware-card-temp{
        width:98%;
        margin-left: 1%;
        display: flex;
        height:140px;
    }
    .bh-image-temp{
        width:45% !important;
    }
    .bh-temp-mob{
        height: 155px !important;
        width:67% !important;
        border: none !important;
        margin-left: 10%;
        margin-top:-20% !important;
    }
    .bh-temp-float-left{
        float:left;
        margin-top:1%;
        font-size:16px !important;
    }
    .bh-temp-desc{
        width:55% !important;
    }
    .mob-title{
        float: left;
        margin-left: -8%;
        width: 85% !important;
    }
    .bh-temp-image-container{
        width: 104% !important;
        margin-top: -10%;
    }
    .brand-bh{
        left: 80% !important;
        top: 15%!important;
        transform: scale(.8);
        width: 78%!important;
        justify-content: left !important;
    }
    .bh-temp-cart{
        transform: scale(0.8);
        margin-left: -6%;
        margin-top:-1%;
        width: 67%;
    }
    .size-btn-bh{
        margin-left:-5%;
        transform: scale(0.8);
        margin-top: 2%;
        width: 50% !important;
    }
    .sch-btn-bh{
        margin-right:0%;
        transform: scale(0.8);
        margin-top: 2%;
        width: 50% !important;
    }
    .bh-temp-2level{
        border: none!important;
    }
    .filters-bh{
        width:50%
    }
    .level2-mob{
        width:150% !important;
        margin-left:-17%;
    }
    .small-cart-bh{
        position: relative;
        left: 37%;
        top: 25%;
    }
    .small-cart-bh-2{
        position: relative;
        left: -8%;
        top: 110%;
    }
    .dropdownstyle1{
        width: 106% !important;
    }
    .dropdownstyle2{
        left: 10%; 
    }
    .dropdownstyle2-details{
        left: 1%;
        top: 0px;
    }
    .atc-details{
        margin: 0;
        width: 100%;
        top: -53px;
        position: relative;
        margin-top: -28%;
        left: 10%;
    }
    .atc-details1{
        margin: -28% 0 0;
        width: 130%;
        top: -12px;
        position: relative;
        left: 0%;
    }
    .small-cart-bh:hover{
        color:red;
        position: relative;
        top: 32%;
        left: 37%;
        .summary-bh{
            display: block;
            border: 1px solid black;
            background-color: white;
            z-index: 100;
            position: relative;
            padding: 5px;
            width: 220px;
            left: -38%;
            margin: 14px 0px 0px -30px;
        }   
    }
    .small-cart-bh-2:hover{
        color:red;
        position: relative;
        top: 32%;
        left: 34%;
        .summary-bh{
            display: block;
            border: 1px solid black;
            background-color: white;
            z-index: 100;
            position: relative;
            padding: 5px;
            width: 220px;
            left: 0%;
        }   
    }
    .bh-title{
        justify-content: left;
        font-size: 13px !important;
        width: 90%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
    }
    .select-size1{
        left: 8%;
        margin-top: -18%;
    }
    .select-size2{
        left: 1%;
        top: -57%;
    }
    .select-size2-1{
        left: 29%;
        top: -57%;
    }
    .select-size-listing1{
        left: 5%;
        top: -4px;
        font-size: 11px;
    }
    .select-size-listing2{
        left: -90%;
        top: -4px;
        font-size: 11px;
    }
    .select-size-listing2-1{
        left: -32%;
        top: -4px;
        font-size: 11px;
    }
    .select-size-listing2-1-long{
        left: -42%;
        top: -4px;
        font-size: 11px;
    }

	.featured-collections {
		.tab-image {
				min-height:160px;
				text-align: center;
				position: relative;
				margin-bottom: 15px !important;
				cursor: pointer;
				overflow: hidden;
				position: relative;
				.background-image {
					position: relative !important;
				  min-height: 160px;
				  width: 120%;
				  margin-left: -10%;
				 
				  background-repeat: no-repeat;
				  -webkit-transition: all .5s;
				  -moz-transition: all .5s;
				  -o-transition: all .5s;
				  transition: all .5s;
					  &:hover {
						transform: scale(1.2);
					  }
				}
				.bg-content{
				  position: absolute;
				  top:50%;
				  left:50%;
				  transform: translate(-50%, -50%);
				   color:#fff;
				  font-size: 25px;
				 
					p{
						font-size: 15px;
					}
				 
				}
				
		}
		.tab-image1 {
			min-height: 130px !important;
			.background-image1 {
				min-height: 130px!important;
			}
			
		}
	}

	  .rounder-corner-nav {
		&.top-nav-right{
			.owl-theme {
				.owl-nav {
					position: absolute;
					top: -70px;
					right: 0;
				}
		}
	}
	&.top-nav-bg-white{
		.owl-theme {
			background-color: transparent !important;
			.owl-nav {
				margin-top:0px;
				background-color: transparent !important;
				.owl-prev,
				.owl-next {
						background: transparent !important;
						border: 0px;
						.fa {
							color:#000;
							
						}
				}
			}
	}
	}
		.owl-theme {
			.owl-nav {
				margin-top:0px;
				z-index: -100;
				position: absolute;
				top:45%;
				display: flex;
				width: 100%;
				text-align: center;
				justify-content: center;
				top:45%;
				.owl-next {				
					position: relative;
					margin-left: 85%;
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;
					.fa {
						color:black;
						font-size: 40px;
					}
				}
				.owl-prev{
					position: relative;
					margin-left: -5%;	
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;
					.fa {
						color:black;
						font-size: 40px;
					}
				}
			}
		}
	}

	
	.mobile-cart{
		display: flex !important;
		float: right !important;
	}

	.desktop-carousel{
		display: none !important;
	}

	.mobile-only{
		display: block !important;
	}
	
	.desktop-only{
		display: none !important;
	}
	.mobile-options{
		position: absolute !important;
    	margin-top: -62px !important;
    	margin: 0;
    	margin-left: 160px;
    	left: 0;
    	border: none !important;
	}
	.mob-eye{
		margin-left:40px
	}
	.mob-second{
		margin-top:-31px
	}
	.forgot-link{
		margin-top:-26px !important
	}
    .forgot-link-checkout{
		margin-top:-45px !important
	}
	.back-btn{
		width:35% !important;
		margin-left:65%;
	}
	
	.mob-logo{
		transform: scale(.75);
		top: 5px!important;
		left: 10%;
		position: fixed;
		z-index: 3;
		width: 65%;
		object-fit: contain;
	}
}
@media screen and (max-width:395px) {
    .select-size-listing2{
        left: 0%;
    }
    .select-size-listing2-1 {
        left: 55%;
    }    
}
@media screen and (max-width:376px) {
    .select-size-listing2{
        top:-9px
    }
    .select-size-listing2-1 {
        top:-9px
    }    
    .select-size-listing1{
        top: -9px;
    }
}
@media screen and (max-width:361px) {
    .atc-details{
        left:15%
    }
    .select-size-listing2-1 {
        left: 52%;
    }  
}


/*changes made in bombay hardwares*/
.bombay_hardwares_bg{
    background-color: #f6f9ff;
}
.bombay_hardwares_bg a {
    color: #4154f1;
}
.our_heritage{
    background-image: url(../../../images/bombayhardware/our_quality.png);
    height: 500px;
    background-position: left;
    background-size: cover;
    position: relative;
}
.our_heritage_content{
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #eceaea;
    margin: 0 60px;
    padding: 10px 28px;
    border-radius: 5px;
}
.our_journey{
    padding: 57px 0 60px;
}

.journey_heading h2{
    color: #012970;
}
.speciality_content_data{
    background-color:#f5f5f5;
    padding: 19px 12px;
    
}


.speciality_content_data h4{
    color: #012970;
}
.speciality_content_data:hover{
    background-color: #6c79e2a1;
    color: white;
}
.portfolio_img{
    padding: 42px 32px 157px 22px;
    transition: all 0.3s ease;
}
.portfolio_img:hover{
    transform: scale(0.8);
}
.our_brands{
    background-color: #2c769d70;
    padding: 32px;
    height: 266px;
    border-radius: 9px;
    box-shadow: 1px 2px 21px #ccc;
}
.our_clients h3{
    padding: 8px 0 28px;
    color: #012970;
}
.our_brands  h3{
    padding: 8px 0 28px;
    color: #012970;
}
.specialty_section{
    padding-top: 54px;
} 
.specialty_section_title h2{
    color:#012970;
}


.portfolio_contnet{
    padding: 92px 20px;
    transition: all 0.3s ease-in;
    // background-color: #f5f5f5;
    // box-shadow: -3px 3px 20px #2f4becc4;
}
// .portfolio_contnet:hover{
//     transform: scale();
// }
.portfolio_contnet h2{
    color: #012970;
}
.portfolio_contnet h4{
    color: #4154f1;
}
.portfolio_contnet p{
    color: #444444;
}


/*changes made in bombay hardwares*/

.bombay_hardwares_bg{
    // background-color: #f6f9ff;
}
.bombay_hardwares_bg a {
    color: #4154f1;
}
.our_heritage{
    background-image: url(../../../images/bombayhardware/our_quality.png);
    height: 500px;
    background-position: left;
    background-size: cover;
    position: relative;
}
.our_heritage_content{
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #eceaea;
    margin: 0 60px;
    padding: 10px 28px;
    border-radius: 5px;
}
.our_heritage_content_info{
    background-color: #eceaea;
    // margin: 0 60px;
    padding: 10px 28px;
    border-radius: 5px;
}
.our_journey{
    padding: 57px 0 60px;
}

.journey_heading h2{
    color: #012970;
}
.speciality_content_data{
    background-color:#f5f5f5;
    padding: 19px 12px;
    
}


.speciality_content_data h4{
    color: #012970;
}
.speciality_content_data:hover{
    background-color: #6c79e2a1;
    color: white;
}

// .portfolio_img{
//     padding: 42px 32px 157px 22px;
//     transition: all 0.3s ease;
// }
.portfolio_img:hover{
    transform: scale(0.8);
}
.our_brands{
    background-color: #1e1e73e8;
    padding: 11px 36px;
    height: 233px;
    border-radius: 9px;
    box-shadow: 1px 2px 21px #ccc;
}
.our_clients h3{
    padding: 8px 0 28px;
    color: #012970;
}
.our_brands  h3{
    padding: 0 0 26px;
    color: white;
}
.specialty_section{
    padding-top: 54px;
} 
.specialty_section_title h2{
    color:#012970;
}


.portfolio_contnet{
    // padding: 92px 20px;
    // transition: all 0.3s ease-in;
    // background-color: #f5f5f5;
    // box-shadow: -3px 3px 20px #2f4becc4;
}
// .portfolio_contnet:hover{
//     transform: scale();
// }
.portfolio_contnet h2{
    color: #012970;
}
.portfolio_contnet h4{
    color: #4154f1;
}
.portfolio_contnet p{
    color: #444444;
}
.supreme_img{
    width: 8%;
    height: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    
}
.tatasteel_img{
    padding:28px 59px;
}
.jgogas_img{
    padding: 28px 43px;
}

.backgrd_color{
    background-color: #cfb77b;
}
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 30px;
    font-weight: 500;
    white-space: nowrap;
  }
  .backgrd_color_change{
    background-color: #1e1e73!important;
    color: white!important;
    border-radius: 20px!important
  }
  .partner_grid{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    padding: 20px;
  }